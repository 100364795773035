import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes';
import { notification, Button } from 'antd';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import "./index.css";

ReactDOM.render(
    <Router>
        <Routes />
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    notification.open({
                        duration: null,
                        placement: 'bottomRight',
                        message: 'Aggiornamento rilevato',
                        description: <div>
                            <div>Un nuovo aggiornamento dell'applicazione è disponibile</div>
                            <div style={{marginTop: 5}}><Button type="primary" onClick={() => window.location.reload()}>Scarica e aggiorna</Button></div>
                        </div>
                    });
                }
            });
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();