import React, { useState, useContext, useEffect } from "react";
import { UserOutlined, UserAddOutlined, LikeOutlined, AppstoreAddOutlined, TeamOutlined, BarsOutlined, MailOutlined, DownloadOutlined, CloseCircleOutlined, ExportOutlined, FileOutlined, LogoutOutlined, QuestionCircleOutlined, PaperClipOutlined, MenuUnfoldOutlined, MenuFoldOutlined, BellOutlined } from '@ant-design/icons';
import { Layout, Menu, message, Divider, Row, Col, Button, Drawer, Badge, Pagination } from 'antd';
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "img/logo-nav.png";
import { UserContext } from 'components/context/user-context';

import logoBG from "img/logo-bg.svg";
import { sendRequest } from 'components/utils/axios.config';

import { useMediaQuery } from 'react-responsive';
import "./template.css";
import { empty } from "components/utils/utils";




const { Header, Sider, Content, Footer } = Layout;

const Notifica = ({ dati, showDrawerDettaglioNotifica, aggiornaListaNotifiche }) => {


    const changeLetto = (id, letto) => {
        sendRequest.patch('/agente_messaggio/' + id, {
            letto: letto ? 0 : 1
        }).then(resp => {
            message.success('Notifica impostata come ' + (letto ? 'da leggere' : 'letta'));
            aggiornaListaNotifiche();
        });
    };

    return <div className='notifica'>
        <Row >
            <Col span={16}>
                <div onClick={() => showDrawerDettaglioNotifica(dati)} >
                    <div style={{ fontSize: '18px', fontWeight: '600' }} className={"titolo-notifica"} >
                        {dati.oggetto}
                    </div>
                    <p>{dati.data}</p>
                </div>
            </Col>
            <Col span={4}>{!empty(dati.allegati) ? <PaperClipOutlined /> : ''}</Col>
            <Col span={4}>
                <Button type="link" className="badge" onClick={() => changeLetto(dati.id, parseInt(dati.letto))}>
                    {parseInt(dati.letto) ? <span className="badge-circle"></span> : <span className="badge-dot"></span>}
                </Button>
            </Col>
        </Row>
    </div >;
}

function Template(props) {

    const isSmartphone = useMediaQuery({ maxWidth: 768 });

    const [collapsed_slider, setCollapedSlider] = useState(isSmartphone);
    const [notifiche, setNotifiche] = useState([]);
    const [numero_notifiche_non_lette, setNumeroNotificheNonLette] = useState(0);
    const [notifica_selezionata, setNotificaSelezionata] = useState({});
    const [pagina_notifiche, setPaginaNotifiche] = useState(1);
    const [drawer_notifiche_visibile, setDrawerNotificheVisible] = useState(false);
    const [drawer_dettaglio_notifica_visibile, setDrawerDettaglioNotificaVisible] = useState(false);

    let drawerWidth = !collapsed_slider ? "35vw" : "100vw";

    let user = useContext(UserContext);
    let location = useLocation();
    let history = useHistory();

    const aggiornaListaNotifiche = () => {
        numeroNotificheNonLette();

        sendRequest.get('/commerciale/notifiche?pagina=' + pagina_notifiche).then(resp => {
            setNotifiche(resp);
        });
    }

    const showDrawerNotifiche = () => {
        setDrawerNotificheVisible(true);
    };

    const onCloseDrawerNotifiche = () => {
        setDrawerNotificheVisible(false);
    }

    const onCloseDrawerDettaglioNotifica = () => {
        setDrawerDettaglioNotificaVisible(false);
    }

    const showDrawerDettaglioNotifica = (notifica) => {
        sendRequest.patch('/agente_messaggio/' + notifica.id, {
            letto: 1
        }).then(resp => {
            aggiornaListaNotifiche();
        });
        setNotificaSelezionata(notifica);
        setDrawerDettaglioNotificaVisible(true);
    };

    var toggle = () => {
        setCollapedSlider(!collapsed_slider);
    }

    var mobileCollapse = () => {
        if (isSmartphone) setCollapedSlider(true);
    }

    const logout = () => {
        sendRequest.get('/commerciale/logout').then(resp => {
            if (resp.result === 'ok') {
                localStorage.setItem("logged", 'false');
                user.updateUserContext({});
                history.push("/login");
            } else if (resp.result === 'ko') {
                message.error(resp.error);
            }
        });
    }

    const onChangePagination = (page) => {
        setPaginaNotifiche(page);
        document.querySelector(".drawer-notifiche .ant-drawer-body").scrollTo({ top: 0, behavior: 'smooth' });
    }

    const scaricaAllegato = (documento) => {
        const url = process.env.REACT_APP_SERVER_URL + '/commerciale/scarica-allegato?file=' + documento;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        // a.download = 'todo-1.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    useEffect(() => {
        aggiornaListaNotifiche();
    }, [pagina_notifiche]);


    const numeroNotificheNonLette = () => {
        sendRequest.get('/commerciale/notifiche/non-lette').then(resp => {
            setNumeroNotificheNonLette(resp);
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            numeroNotificheNonLette();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Layout>
                <img src={logoBG} id="logoBg" />
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed_slider}
                    collapsedWidth={isSmartphone ? '0' : '80'}
                    width="270"
                    style={{ zIndex: 2 }}
                    className="sider-menu"
                >
                    <Link to="/" style={{
                        textAlign: 'center'
                    }}>

                        <div className="logo" style={{ height: '64px', padding: '10px' }}>
                            <img src={logo} className="logo" />

                        </div>
                    </Link>
                    {isSmartphone ? <div style={{ textAlign: 'right' }}>
                        <CloseCircleOutlined
                            className="close-menu-icon"
                            onClick={toggle}
                            style={{
                                fontSize: "20px",
                                padding: "15px",
                                color: 'white'
                            }} />
                    </div> : ''}
                    <Divider style={{ margin: 0 }} />
                    <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
                        {!collapsed_slider ? <h3 style={{ textAlign: 'center', padding: '40px 0', fontSize: '25px' }}>Aree Operative</h3> : ""}
                        <Menu.Item key="/portfolio-utenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/portfolio-utenti" onClick={mobileCollapse}>
                                <UserOutlined />
                                <span>Portfolio Utenti</span>
                            </Link>
                        </Menu.Item>
                        {(empty(user.info.id_agente)) && <Menu.Item key="/agenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/agenti" onClick={mobileCollapse}>
                                <TeamOutlined />
                                <span>Agenti</span>
                            </Link>
                        </Menu.Item>}
                        <Menu.Item key="/registrazione-utenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/registrazione-utenti" onClick={mobileCollapse}>
                                <UserAddOutlined />
                                <span>Registrazione Utenti</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/approvazione-utenti">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/approvazione-utenti" onClick={mobileCollapse}>
                                <LikeOutlined />
                                <span>Approvazione Utenti</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/attivazione-risorse">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/attivazione-risorse" onClick={mobileCollapse}>
                                <AppstoreAddOutlined />
                                <span>Attivazione Risorse</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="/materiale-aggiuntivo">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/materiale-aggiuntivo" onClick={mobileCollapse}>
                                <DownloadOutlined />
                                <span>Materiale Aggiuntivo</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/liste">
                            <Link style={{ display: 'flex', alignItems: 'center' }} to="/liste" onClick={mobileCollapse}>
                                <BarsOutlined />
                                <span>Liste</span>
                            </Link>
                        </Menu.Item>
                        <Menu.SubMenu
                            key="/newsletter"
                            title={
                                <Link className="link" style={{ color: "#000", width: "100%" }} to="/newsletter" onClick={mobileCollapse}>
                                    <MailOutlined />
                                    <span>Newsletter</span>
                                </Link>
                            }
                        >
                            <Menu.Item key="/newsletter/nuovo-invio">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/nuovo-invio" onClick={mobileCollapse}>Nuova</Link>
                            </Menu.Item>

                            <Menu.Item key="/newsletter/inviate">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/inviate" onClick={mobileCollapse}>Inviate</Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/coda">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/coda" onClick={mobileCollapse}>Coda di invio</Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/statistiche">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/statistiche" onClick={mobileCollapse}>Statistiche generali</Link>
                            </Menu.Item>
                            <Menu.Item key="/newsletter/impostazioni">
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/newsletter/impostazioni" onClick={mobileCollapse}>Impostazioni</Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu>

                </Sider>
                <Layout >
                    <Header>
                        <Row >
                            <Col xs={8} md={10}>
                                {React.createElement(collapsed_slider ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggle,
                                    style: {
                                        textAlign: 'left',
                                        fontSize: "20px",
                                        padding: "0 15px"
                                    }
                                })}
                            </Col>
                            <Col xs={8} md={0}>
                                <Link to="/"><img src={logo} className="logo" /></Link>
                            </Col>
                            <Col xs={4} md={10} style={{ textAlign: 'right' }}>

                                <Badge count={numero_notifiche_non_lette}>
                                    <Button className={'btn-notifiche'} onClick={showDrawerNotifiche} icon={<BellOutlined />}>{!isSmartphone ? 'CENTRO NOTIFICHE' : ''}</Button>
                                </Badge>

                            </Col>
                            <Col xs={4} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                <a onClick={logout} className="logout" style={{ textAlign: 'right', color: 'inherit' }}>
                                    {!isSmartphone ? <span>LOGOUT</span> : ""}
                                    <LogoutOutlined
                                        style={{
                                            fontSize: "20px",
                                            padding: "0 15px 0 5px",
                                            verticalAlign: 'sub'
                                        }} />
                                </a>
                            </Col>
                        </Row>



                    </Header>
                    <Content
                        style={{
                            margin: '15px 15px 0px',
                            minHeight: "calc(100vh - 148px)"
                        }}
                        className="container-content"
                        id="main-content"
                    >
                        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
                            {isSmartphone ?
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/tutorial" onClick={mobileCollapse}>
                                    <Button shape={'circle'} className="help" size="large" style={{ background: '#eee' }} icon={<QuestionCircleOutlined />} /></Link> :
                                <Link style={{ display: 'flex', alignItems: 'center' }} to="/tutorial" onClick={mobileCollapse}><Button size="large" className="help">Serve aiuto <QuestionCircleOutlined /> </Button></Link>}
                        </div>
                        {props.children}
                    </Content>
                    <Footer style={{ textAlign: 'center', background: '#eee' }}>
                        <span style={{ padding: '10px 20px', fontSize: 11 }}>Engineering and design by <a href="https://www.sernicola-labs.com/" target="_blank">Sernicola Labs Srl</a></span>
                    </Footer>

                </Layout>
            </Layout>
            <Drawer
                className="drawer-notifiche"
                width={drawerWidth}
                placement="right"
                closable={false}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type='danger' onClick={onCloseDrawerNotifiche}>CHIUDI</Button>
                    </div>
                }
                onClose={onCloseDrawerNotifiche}
                visible={drawer_notifiche_visibile}
            >
                <h2>Notifiche</h2>
                <Divider />
                {!empty(notifiche) && notifiche.records.map((notifica) => (
                    <Notifica
                        key={notifica.id}
                        dati={notifica}
                        showDrawerDettaglioNotifica={showDrawerDettaglioNotifica}
                        aggiornaListaNotifiche={aggiornaListaNotifiche}
                    />
                ))}
                <Pagination
                    total={notifiche.total}
                    current={pagina_notifiche}
                    pageSize={notifiche.page_size}
                    showSizeChanger={false}
                    onChange={onChangePagination}
                    style={{ textAlign: 'right', marginTop: 15 }}
                />
            </Drawer>
            <Drawer
                title={
                    <h3>{notifica_selezionata.oggetto}</h3>
                }
                width={350}
                closable={false}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type='danger' onClick={onCloseDrawerDettaglioNotifica}>CHIUDI</Button>
                    </div>
                }
                onClose={onCloseDrawerDettaglioNotifica}
                visible={drawer_dettaglio_notifica_visibile}
                key={notifica_selezionata.id}
            >
                {!empty(notifica_selezionata.allegati) ?
                    <>
                        <Row>
                            {notifica_selezionata.allegati.map((documento) => (

                                <Col key={documento}>
                                    <Button icon={<FileOutlined />} onClick={() => scaricaAllegato(documento)}>{documento}</Button>
                                </Col>

                            ))}
                        </Row>
                        <Divider />
                    </>
                    :
                    ''}
                <p>{notifica_selezionata.testo}</p>
                <Divider />
                <p style={{ textAlign: 'right' }}>{notifica_selezionata.data}</p>
            </Drawer>
        </>
    );
}

export default Template;