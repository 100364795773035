import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Spin, Row, ConfigProvider, message, Skeleton, Modal } from 'antd';
import itIT from 'antd/es/locale/it_IT';
import { Switch, Route, Redirect } from "react-router-dom";
import { Template } from "./components/template";
import { UserContext } from 'components/context/user-context';
import { sendRequest } from 'components/utils/axios.config';
import Lottie from 'react-lottie';
import animationData from 'img/logo.json';
import { empty } from 'components/utils/utils';
import useIosInstallPrompt from 'components/installa-pwa/ios-install-prompt';
import useWebInstallPrompt from 'components/installa-pwa/pwa-install-prompt';
import iosActionLogo from "img/ios-action.png";

import "antd/dist/antd.less";

const Login = lazy(() => import('./views/login'));
const Home = lazy(() => import('./views/home'));
const RegistrazioneUtenti = lazy(() => import('./views/registrazione-utenti'));
const PortfolioUtenti = lazy(() => import('./views/portfolio-utenti'));
const ApprovazioneUtenti = lazy(() => import('./views/approvazione-utenti'));
const MaterialeAggiuntivo = lazy(() => import('./views/materiale-aggiuntivo'));
const AttivazioneRisorse = lazy(() => import('./views/attivazione-risorse'));
const Newsletter = lazy(() => import('./views/newsletter'));
const NewsletterNuova = lazy(() => import('./views/newsletter-nuova'));
const NewsletterInviate = lazy(() => import('./views/newsletter-inviate'));
const NewsletterCoda = lazy(() => import('./views/newsletter-coda'));
const NewsletterStatistiche = lazy(() => import('./views/newsletter-statistiche'));
const NewsletterImpostazioni = lazy(() => import('./views/newsletter-impostazioni'));
const Liste = lazy(() => import('./views/liste'));
const Agenti = lazy(() => import('./views/agenti'));
const Tutorial = lazy(() => import('./views/tutorial'));

var first_render = true;

const DelayedSpinner = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => setShowSpinner(true), 600);

        return () => clearTimeout(timer);
    });

    return showSpinner && <div
        id="caricamento-globale">
        <Lottie options={defaultOptions}
            height={115}
            width={88}
        />
    </div>;
};

function Routes() {

    var [activeRequests, setActiveRequests] = useState(0);
    var [user_info, setUserInfo] = useState({});

    if (localStorage.getItem("logged") == undefined) {
        localStorage.setItem("logged", 'false');
    }

    const getUserInfo = async () => {
        let response = await sendRequest.get('/commerciale/info');
        setUserInfo(response);
    }

    if (first_render) {
        /**
         * impostazioni axios
         */
        // Add a request interceptor
        sendRequest.interceptors.request.use(function (config) {
            // Do something before request is sent
            setActiveRequests(activeRequests + 1);
            return config;
        }, function (error) {
            // Do something with request error
            setActiveRequests(activeRequests - 1);
            return Promise.reject(error);
        });
        // Add a response interceptor
        sendRequest.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // console.log(response);
            setActiveRequests(activeRequests - 1);
            return response.data;
        }, function (error) {

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error.response.status === 403) {
                message.error('Non hai i permessi per accedere a questa risorsa');
                localStorage.setItem("logged", 'false');
            }
            if (error.response.status === 404) {
                message.error('404 Url not found');
            }
            if (error.response.status === 400) {
                message.error(error.response.data ? error.response.data : 'Errore durante la richiesta');
            }
            setActiveRequests(activeRequests - 1);
            return Promise.reject(error);
        });
        /**
         * fine impostazioni axios
         */

        if (localStorage.getItem("logged") === 'true') {
            getUserInfo();
        }

        first_render = false;
    }

    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    useEffect(() => {
        if (iosInstallPrompt) {
            Modal.info({
                title: 'E\' possibile installare questa applicazione sul tuo dispositivo',
                icon: null,
                content: <div>Per installare questa applicazione da un dispositivo Apple devi essere sul browser <b>Safari</b>.<br></br> Andare quindi sull'icona <img src={iosActionLogo} style={{ height: 24, marginTop: -5 }} /> e selezionare "Aggiungi alla schermata Home" </div>,
                okText: 'Ho capito',
                onOk() {
                    handleIOSInstallDeclined();
                }
            });
        }

        if (webInstallPrompt) {
            Modal.confirm({
                title: 'E\' possibile installare questa applicazione sul tuo dispositivo',
                icon: null,
                content: <div>Vuoi procedere con l'installazione?</div>,
                okText: 'Installa',
                cancelText: 'Continua da browser',
                onOk() {
                    handleWebInstallAccepted();
                },
                onCancel() {
                    handleWebInstallDeclined();
                }
            });
        }


    }, [webInstallPrompt]);

    return (
        <div >
            <ConfigProvider locale={itIT}>
                <UserContext.Provider value={{ info: user_info, updateUserContext: setUserInfo }}>
                    {localStorage.getItem("logged") !== 'true' ?
                        <Suspense fallback={
                            <Row type="flex" justify="space-around" align="middle" style={{ minHeight: "calc(100vh - 112px)" }}>
                                <Spin />
                            </Row>
                        }
                        >
                            <Switch>
                                <Route exact path="/login">
                                    <Login />
                                </Route>
                                <Route path="/">
                                    <Redirect to="/login" />
                                </Route>
                            </Switch>
                        </Suspense> : ((!empty(user_info)) ? <Template>
                            <Suspense fallback={<Skeleton active />}>
                                <Switch>
                                    <Route exact path="/">
                                        <Home />
                                    </Route>
                                    <Route exact path="/attivazione-risorse">
                                        <AttivazioneRisorse />
                                    </Route>
                                    <Route exact path="/registrazione-utenti">
                                        <RegistrazioneUtenti />
                                    </Route>
                                    <Route exact path="/portfolio-utenti">
                                        <PortfolioUtenti />
                                    </Route>
                                    <Route exact path="/approvazione-utenti">
                                        <ApprovazioneUtenti />
                                    </Route>
                                    <Route exact path="/materiale-aggiuntivo">
                                        <MaterialeAggiuntivo />
                                    </Route>
                                    <Route exact path="/newsletter">
                                        <Newsletter />
                                    </Route>
                                    <Route exact path="/newsletter/nuovo-invio">
                                        <NewsletterNuova />
                                    </Route>
                                    <Route exact path="/newsletter/inviate">
                                        <NewsletterInviate />
                                    </Route>
                                    <Route exact path="/newsletter/coda">
                                        <NewsletterCoda />
                                    </Route>
                                    <Route exact path="/newsletter/statistiche">
                                        <NewsletterStatistiche />
                                    </Route> 
                                    <Route exact path="/newsletter/impostazioni">
                                        <NewsletterImpostazioni />
                                    </Route>
                                    <Route exact path="/liste">
                                        <Liste />
                                    </Route>

                                    <Route exact path="/agenti">
                                        <Agenti />
                                    </Route>
                                    <Route exact path="/tutorial">
                                        <Tutorial />
                                    </Route>
                                </Switch>
                            </Suspense>

                        </Template> : null)

                    }
                </UserContext.Provider>
            </ConfigProvider>
            {activeRequests > 0 && <DelayedSpinner />}

        </div>
    );
}


export default Routes;
